import React, { useState, useEffect } from "react";
import PuzzleBackground from "./puzzle-background";
import styles from "./intro.module.css";
import puzzle_red from "../images/logo_red1.png";
import puzzle_green from "../images/logo_green1.png";
import puzzle_orange from "../images/logo_orange1.png";
import puzzle_blue from "../images/logo_blue1.png";

const Intro = ({ disableIntro }) => {
  useEffect(() => {
    // clean-up function used to register leaving the home page for the first time
    return () => {
      disableIntro();
    };
  });

  const [isClicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };

  return (
    !isClicked && (
      <div onClick={handleClick} className={styles.intro_overlay}>
        <PuzzleBackground />
        <div className={styles.logo_container}>
          <div className={styles.logo}>
            <div className={styles.puzzle_red_container}>
              <img className={styles.puzzle} src={puzzle_red} />
            </div>
            <div className={styles.puzzle_green_container}>
              <img className={styles.puzzle} src={puzzle_green} />
            </div>
            <div className={styles.puzzle_orange_container}>
              <img className={styles.puzzle} src={puzzle_orange} />
            </div>
            <div className={styles.puzzle_blue_container}>
              <img className={styles.puzzle} src={puzzle_blue} />
            </div>
          </div>
        </div>
        <h1 className={styles.intro_title}>Witamy na&nbsp;stronie PCPR w&nbsp;Żyrardowie!!!</h1>
      </div>
    )
  );
};

export default Intro;
