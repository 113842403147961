import React from "react";
import { Link } from "gatsby";
import styles from "./news-navigation-link.module.css";

const NewsNavigationLink = ({ to, rel, text, isCurrent }) => {
  return (
    <Link
      className={
        isCurrent
          ? `${styles.navigation_link_active} ${styles.navigation_link}`
          : styles.navigation_link
      }
      to={to}
      rel={rel}
    >
      {text}
    </Link>
  );
};

export default NewsNavigationLink;
